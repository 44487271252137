import {
  ConditionValueType,
  DimensionsUnitValueType,
  EavAttributeBooleanValueType,
  HoldbackAmountValueType,
  IdentifiersValueType,
  MarginTypeValueType,
  PricingRestrictionTypesValueType,
  WarrantyDateValueType,
  WeightUnitValueType
} from '@/types/enums/product/options';
import {
  CarrierValueType,
  MarketplaceValueType
} from '@/types/enums/order/options';
import { PartnerType } from '@/types/enums/store/partner/partner';

export function useOptions() {
  const dimensionsUnitOptions = [
    { value: DimensionsUnitValueType[''], label: 'Select unit' },
    { value: DimensionsUnitValueType.MM, label: 'millimeters' },
    { value: DimensionsUnitValueType.CM, label: 'centimeters' },
    { value: DimensionsUnitValueType.M, label: 'meters' },
    { value: DimensionsUnitValueType.IN, label: 'inches' },
    { value: DimensionsUnitValueType.FT, label: 'feet' }
  ];

  const weightUnitOptions = [
    { value: WeightUnitValueType[''], label: 'Select unit' },
    { value: WeightUnitValueType.G, label: 'grams' },
    { value: WeightUnitValueType.KG, label: 'kilograms' },
    { value: WeightUnitValueType.OZ, label: 'ounce' },
    { value: WeightUnitValueType.LB, label: 'pounds' }
  ];

  const identifiersTypeOptions = [
    { value: IdentifiersValueType[''], label: 'Select type' },
    { value: IdentifiersValueType.GTIN, label: 'GTIN' },
    { value: IdentifiersValueType.UPC, label: 'UPC' },
    { value: IdentifiersValueType.EAN, label: 'EAN' },
    { value: IdentifiersValueType.IAN, label: 'IAN' }
  ];

  const eavAttributeBooleanOptions = [
    { value: EavAttributeBooleanValueType[''], label: 'Select option' },
    {
      value: EavAttributeBooleanValueType.TRUE === 'true',
      label: 'Yes'
    },
    { value: EavAttributeBooleanValueType.FALSE === 'false', label: 'No' }
  ];

  const conditionOptions = [
    { value: ConditionValueType.NEW, label: 'New' },
    { value: ConditionValueType.OPEN, label: 'Open box' },
    { value: ConditionValueType.RECERTIFIED, label: 'Certified Refurbished' },
    { value: ConditionValueType.OTHER, label: 'Other' }
  ];

  const marginTypeOptions = [
    { value: MarginTypeValueType[''], label: 'Select field' },
    { value: MarginTypeValueType.FULFILLMENT_COST, label: 'Fulfillment cost' },
    { value: MarginTypeValueType.LISTING_PRICE, label: 'Listing price' },
    { value: MarginTypeValueType.MSRP, label: 'MSRP' }
  ];

  const warrantyDateOptions = [
    { value: WarrantyDateValueType[''], label: 'Select days, months or years' },
    { value: WarrantyDateValueType.DAYS, label: 'Days' },
    { value: WarrantyDateValueType.MONTHS, label: 'Months' },
    { value: WarrantyDateValueType.YEARS, label: 'Years' }
  ];

  const pricingRestrictionTypes = [
    {
      value: PricingRestrictionTypesValueType[''],
      label: 'Select type of pricing restriction'
    },
    { value: PricingRestrictionTypesValueType.UPP, label: 'UPP' },
    { value: PricingRestrictionTypesValueType.MAP, label: 'MAP' },
    { value: PricingRestrictionTypesValueType.FLOOR, label: 'Floor List' }
  ];

  const ownerOptions = [
    {
      label: 'Brand',
      value: PartnerType.BRAND
    },
    {
      label: 'Fulfillment Partner',
      value: PartnerType.DISTRIBUTION_PARTNER
    },
    {
      label: 'Marketplace',
      value: PartnerType.MARKETPLACE
    },
    {
      label: 'Marketnation',
      value: PartnerType.MARKETNATION
    }
  ];

  const holdbackAmountOptions = [
    {
      value: HoldbackAmountValueType.PERCENT,
      label: '%'
    },
    {
      value: HoldbackAmountValueType.UNITS,
      label: 'units'
    }
  ];

  const marketplaceOptions = [
    { value: MarketplaceValueType.WALMART, label: 'Walmart' },
    { value: MarketplaceValueType.EBAY, label: 'eBay' },
    { value: MarketplaceValueType.CUSTOMER_NATION, label: 'CustomerNation' },
    { value: MarketplaceValueType.PLUS_SHOP, label: 'Plus-Shop' },
    { value: MarketplaceValueType.AMAZON, label: 'Amazon' }
  ];

  const marketplaceOptionsWithNumberValue = [
    { value: 1, label: 'Amazon' },
    { value: 2, label: 'eBay' },
    { value: 3, label: 'Google Shopping' },
    { value: 6, label: 'Walmart' },
    { value: 7, label: '+Shop' }
  ];

  const carrierOptions = [
    {
      value: CarrierValueType.UPS,
      label: 'UPS'
    },
    {
      value: CarrierValueType.USPS,
      label: 'USPS'
    },
    {
      value: CarrierValueType.FEDEX,
      label: 'FedEx'
    },
    {
      value: CarrierValueType.AIRBORNE,
      label: 'Airborne'
    },
    {
      value: CarrierValueType.ONTRAC,
      label: 'OnTrac'
    },
    {
      value: CarrierValueType.DHL,
      label: 'DHL'
    },
    {
      value: CarrierValueType.LS,
      label: 'Laser Ship'
    },
    {
      value: CarrierValueType.UDS,
      label: 'United Delivery Service'
    },
    {
      value: CarrierValueType.UPSMI,
      label: 'UPS Mail Innovations'
    },
    {
      value: CarrierValueType.FDX,
      label: 'FDX'
    },
    {
      value: CarrierValueType.PILOT,
      label: 'PILOT'
    },
    {
      value: CarrierValueType.ESTES,
      label: 'ESTES'
    },
    {
      value: CarrierValueType.SAIA,
      label: 'SAIA'
    },
    {
      value: CarrierValueType.FDS_EXPRESS,
      label: 'FDS Express'
    },
    {
      value: CarrierValueType.SEKO_WORLDWIDE,
      label: 'Seko Worldwide'
    },
    {
      value: CarrierValueType.HIT_DELIVERY,
      label: 'HIT Delivery'
    },
    {
      value: CarrierValueType.FEDEXSP,
      label: 'FedEx Smart Post'
    }
  ];

  return {
    dimensionsUnitOptions,
    weightUnitOptions,
    identifiersTypeOptions,
    eavAttributeBooleanOptions,
    conditionOptions,
    marginTypeOptions,
    warrantyDateOptions,
    pricingRestrictionTypes,
    ownerOptions,
    holdbackAmountOptions,
    marketplaceOptions,
    carrierOptions,
    marketplaceOptionsWithNumberValue
  };
}
