<template src="./template.pug" lang="pug"></template>

<script>
import { mapGetters } from 'vuex';
import formatters from '@/mixins/formatters';
import gridGetters from './mixins/gridGetters';
import StorageService from '@/services/storage.service';
import bulkEditModal from '@/modals/bulk-edit';
import adminBulkEditModal from '@/modals/admin-bulk-edit';
import bulkUploadModal from '@/modals/bulk-upload';
import exportProductsModal from '@/modals/export-products';
import { GRID_PRODUCTS } from '../../types/constants';
import { useUserRoles } from '@/composables/userRoles';

export default {
  name: 'Products',
  components: {
    exportProductsModal,
    bulkEditModal,
    bulkUploadModal,
    adminBulkEditModal
  },
  mixins: [formatters, gridGetters],

  data() {
    return {
      activeTab: 'my-products',
      tabs: [
        {
          code: 'my-products',
          name: 'My products'
        },
        {
          code: 'attention-needed',
          name: 'Attention Needed'
        }
      ],
      productMP: [
        {
          name: 'identifier',
          label: 'IDENTIFIER',
          colWidth: null,
          search: 'single',
          type: 'text',
          default: '-'
        },
        {
          name: 'sku',
          label: 'SKU',
          colWidth: null,
          search: 'single',
          type: 'text',
          default: '-'
        },
        {
          name: 'title',
          label: 'Product title',
          colWidth: null,
          search: 'single',
          type: 'text',
          default: '-'
        },
        {
          name: 'fulfillments',
          label: 'Fulfillment',
          colWidth: null,
          search: 'multi',
          type: 'text'
        },
        {
          name: 'status',
          label: 'MP Status',
          colWidth: null,
          search: 'multi',
          type: 'text'
        },
        {
          name: 'totalQuantity',
          label: 'Total Qty',
          colWidth: null,
          search: 'range',
          type: 'number',
          default: 0
        },
        {
          name: 'marketplaceQuantity',
          label: 'MP Qty',
          colWidth: null,
          search: 'range',
          type: 'number',
          default: 0
        },
        {
          name: 'netProceeds',
          label: 'Net proceeds',
          colWidth: '',
          search: 'range',
          type: 'number'
        }
      ],
      productAN: [
        {
          name: 'identifier',
          label: 'IDENTIFIER',
          colWidth: null,
          search: 'single',
          type: 'text',
          default: '-'
        },
        {
          name: 'sku',
          label: 'SKU',
          colWidth: null,
          search: 'single',
          type: 'text',
          default: '-'
        },
        {
          name: 'title',
          label: 'Product title',
          colWidth: null,
          search: 'single',
          type: 'text',
          default: '-'
        },
        {
          name: 'fulfillments',
          label: 'Fulfillment',
          colWidth: null,
          search: 'multi',
          type: 'text'
        },
        {
          name: 'productStatus',
          label: 'Opp Status',
          colWidth: null,
          search: 'multi',
          type: 'text'
        },
        {
          name: 'quantity',
          label: 'Qty',
          colWidth: null,
          search: 'range',
          type: 'number',
          default: 0
        },
        {
          name: 'netProceeds',
          label: 'Net proceeds',
          colWidth: '',
          search: 'range',
          type: 'number'
        }
      ],
      StorageService: StorageService,
      showBulkEditModal: false,
      showExportProductsModal: false,
      showBulkUploadModal: false,
      showAdminEditModal: false,
      helpCenterBannerLink:
        'https://marketnation.notion.site/marketnation/MarketNation-s-Help-Center-96d3b050bec4449596e779a0628c6b7b'
    };
  },
  mounted() {
    this.activeTab = this.$route?.query?.activeTab || 'my-products';
    this.loadGridProducts(StorageService.getObject('productGridFilters'));
    this.loadGridAttentionNeededProducts(
      StorageService.getObject('anProductGridFilters')
    );
    this.showBulkUploadModal = JSON.parse(
      StorageService.getItem('showBulkUploadModal')
    );
  },

  computed: {
    ...mapGetters('product', [
      'GET_GRID_PRODUCTS',
      'GET_GRID_AN_PRODUCTS',
      'IS_LOADING_PRODUCTS'
    ]),

    needAttention() {
      if (!this.GET_GRID_AN_PRODUCTS) {
        return false;
      }

      return this.GET_GRID_AN_PRODUCTS.need_attentions;
    }
  },

  methods: {
    loadGridProducts(filter) {
      this.$store.dispatch(
        'product/' + GRID_PRODUCTS.ACTIONS.GET_PRODUCTS_GRID,
        filter || {}
      );
    },

    loadGridAttentionNeededProducts(filter) {
      this.$store.dispatch(
        'product/' + GRID_PRODUCTS.ACTIONS.GET_AN_PRODUCTS_GRID,
        filter || {}
      );
    },
    saveGridFiltersToLocalStorage(filter) {
      StorageService.setObject('productGridFilters', filter);
      this.loadGridProducts(filter);
    },

    saveANProductsGridFiltersToLocalStorage(filter) {
      StorageService.setObject('anProductGridFilters', filter);
      this.loadGridAttentionNeededProducts(filter);
    },

    openBulkUploadModal() {
      this.showBulkUploadModal = true;
    },

    openBulkEditModal() {
      this.showBulkEditModal = true;
    },

    openExportProductModal() {
      this.showExportProductsModal = true;
    },
    openAdminEditModal() {
      this.showBulkEditModal = false;
      this.showAdminEditModal = true;
    },

    getAmountOfANProducts() {
      if (!this.GET_GRID_AN_PRODUCTS) {
        return 0;
      }
      if (!this.GET_GRID_AN_PRODUCTS.pagination) {
        return 0;
      }
      return this.GET_GRID_AN_PRODUCTS.pagination.totalElements;
    },

    getAmountOfProducts() {
      if (!this.GET_GRID_PRODUCTS) {
        return 0;
      }
      if (!this.GET_GRID_PRODUCTS.pagination) {
        return 0;
      }
      return this.GET_GRID_PRODUCTS.pagination.totalElements;
    },
    closeExportProductsModal() {
      this.showExportProductsModal = false;
    },
    closeBulkEditModal() {
      this.showBulkEditModal = false;
    },
    closeAdminEditModal() {
      this.showAdminEditModal = false;
    },
    closeBulkUploadModal() {
      this.showBulkUploadModal = false;
    },

    closeHelpCenterBanner() {
      document.cookie =
        'display_help_center_banner_cookie=0; max-age=315360000; path=/';
    },

    showHelpCenterBanner() {
      return (
        document.cookie.indexOf('display_help_center_banner_cookie=0') === -1
      );
    },

    openHelpCenterLink() {
      window.open(this.helpCenterBannerLink);
    }
  }
};
</script>
