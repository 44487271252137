import { onMounted, ref, nextTick, computed, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useMarginStackNavItems } from '@/composables/views/products/product-details/margin-stack/marginStackNavItems';
import { useStore } from 'vuex';
import { PD_CONT, TREATMENT } from '@/types/constants';

export function useSideNav(
  activeHorizontalTab: Ref<string | undefined>,
  horizontalTabCodes: {
    PRODUCT_CONTENT: 'product-content';
    SKU_DETAILS: 'sku-details';
    MARGIN_STACK: 'margin-stack';
  },
  sideNav: Ref<any>
) {
  const activeSideNavItem = ref();
  const route = useRoute();
  const store = useStore();

  const isCategoryAttributesAccordionHidden = computed(() => {
    const getters =
      'product/' + PD_CONT.GETTERS.IS_CATEGORY_ATTRIBUTES_ACCORDION_VISIBLE;
    return !store.getters[getters];
  });

  const productComplianceFlag = computed(() => {
    return !store.getters['treatment/' + TREATMENT.GETTERS.GET_USER_TREATMENT](
      'product-compliance'
    );
  });

  const productContentNavItems = ref([
    { label: 'Basic product info *', code: 'basic-product-info' },
    { label: 'Media', code: 'media' },
    { label: 'Description', code: 'description' },
    {
      label: 'Category Attributes',
      code: 'category-attributes',
      hidden: isCategoryAttributesAccordionHidden
    },
    { label: 'Dimensions & weight', code: 'dimensions-and-weight' },
    { label: 'Compliance', code: 'compliance', hidden: productComplianceFlag }
  ]);

  const skuDetailsNavItems = ref([
    { label: 'Basic offer info *', code: 'basic-offer-info' },
    { label: 'Channels *', code: 'channels' },
    { label: 'Promotions', code: 'promotions' },
    { label: 'Inventory', code: 'inventory' },
    { label: 'Offer specific content', code: 'offer-specific-content' },
    { label: 'Dimensions & weight', code: 'dimensions-and-weight' }
  ]);

  const { marginStackNavItems } = useMarginStackNavItems();

  const getSideNavItems = () => {
    switch (activeHorizontalTab.value) {
      case horizontalTabCodes.PRODUCT_CONTENT:
        return productContentNavItems.value;
      case horizontalTabCodes.SKU_DETAILS:
        return skuDetailsNavItems.value;
      case horizontalTabCodes.MARGIN_STACK:
        return marginStackNavItems.value;
      default:
        return [];
    }
  };

  const setActiveSideNavItem = async (value) => {
    activeSideNavItem.value = value;
    await nextTick();
    sideNav.value?.scrollToElement(value);
  };

  const setFirstSideNavItemAsActiveElement = () => {
    activeSideNavItem.value = getSideNavItems()[0]?.code;
    const scrollContainer = document.getElementById('scroll_container');
    scrollContainer?.scrollTo(0, 0);
  };

  const setActiveSideNavItemBasedOnRouteHash = () => {
    const sideNavItem = route.hash?.replace('#', '')?.split('/')[1];

    if (sideNavItem) {
      return setActiveSideNavItem(sideNavItem);
    }

    setFirstSideNavItemAsActiveElement();
  };

  onMounted(async () => {
    setTimeout(setActiveSideNavItemBasedOnRouteHash, 1000);
  });

  return {
    activeSideNavItem,
    getSideNavItems,
    setActiveSideNavItem,
    setFirstSideNavItemAsActiveElement
  };
}
