import * as PartnerType from '@/mixins/partnerType';
import { mapGetters } from 'vuex';

const mixin = {
  computed: {
    ...mapGetters('partner', [
      'GET_FULFILLMENT_AND_BRAND_PARTNERS',
      'GET_MARKETPLACES'
    ])
  },
  methods: {
    getFulfillmentWithSelfFulfilledOptions() {
      let fulfillments = [...this.GET_FULFILLMENT_AND_BRAND_PARTNERS];
      fulfillments = this.getFulfillmentsWithSelfFulfilled(fulfillments);
      return fulfillments.map((item) => {
        return {
          value: item.id,
          label: item.name,
          type: item.type
        };
      });
    },

    getFulfillmentsWithSelfFulfilled(fulfillments) {
      if (!fulfillments) {
        return [];
      }

      const brandsIds = [];
      fulfillments.forEach((item) => {
        if (item.type === PartnerType.BRAND) {
          brandsIds.push(item.id);
        }
      });

      fulfillments.push({
        id: brandsIds,
        name: 'Self-Fulfilled',
        type: 'SELF_FULFILLED'
      });

      return fulfillments;
    },

    getMarketplacesWithStatusOptions() {
      let marketplacesWithStatus = [];
      const statusOptions = this.getStatusOptions();
      const marketplaceOptions = this.getMarketplaceOptions();

      statusOptions.forEach((status) => {
        marketplacesWithStatus.push({
          group: 'status',
          label: status.label,
          value: status.value
        });
      });

      marketplaceOptions.forEach((marketplace) => {
        marketplacesWithStatus.push({
          group: 'marketplace',
          label: marketplace.label,
          value: marketplace.value
        });
      });

      return marketplacesWithStatus;
    },

    getMarketplaceOptions() {
      let marketplaceOptions = [];
      this.GET_MARKETPLACES.forEach((item) => {
        marketplaceOptions.push({ value: item.id, label: item.name });
      });

      return marketplaceOptions;
    },

    getStatusOptions() {
      return [
        {
          label: 'Active',
          value: 'active'
        },
        {
          label: 'Inactive',
          value: 'inactive'
        },
        {
          label: 'Published',
          value: 'published'
        },
        {
          label: 'Pending Publish',
          value: 'pending_publish'
        },
        {
          label: 'Publish Failed',
          value: 'publish_failed'
        },
        {
          label: 'Unpublished',
          value: 'unpublished'
        },
        {
          label: 'Pending Unpublish',
          value: 'pending_unpublish'
        },
        {
          label: 'Unpublish Failed',
          value: 'unpublish_failed'
        },
        { label: 'Publish Inventory Hold', value: 'publish_inventory_hold' }
      ];
    }
  }
};

export default mixin;
